import React from 'react';
import { makeStyles, AppBar, Toolbar, Typography, Link, Menu, MenuItem, IconButton, Container, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Logo from '../assets/images/logo.inline.svg';
//import Logo from '../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
  },
  toolbarContainer: {
    padding: theme.spacing(5, 0, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  leftContainer: {
    flexGrow: 2,
  },
  rightContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: 48,
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      height: 96,
      width: 'auto',
      marginLeft: '2rem'
    },
  },
  menuItem: {
    fontSize: 14,
    '&:hover': {
      textDecoration: 'none',
    },
  }
}));

export default function Header() {
  const classes = useStyles();

  const [anchorEl, open] = React.useState(null);  
  const handleOpen = event => {  
    open(event.currentTarget);  
  };  

  const handleClose = () => {  
    open(null);  
  };

  return (
    <div className={classes.container} id="top">
      <Container>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar className={classes.toolbarContainer}>
            <div className={classes.leftContainer}>
              <Logo/>
            </div>
            <Hidden smDown>
              <div className={classes.rightContainer}>
                <Link color="inherit" href="/#top">
                  <Typography variant="h5">
                    About
                  </Typography>
                </Link>
                <Typography variant="h5">
                  <Link color="inherit" href="/#bi">Business Intelligence</Link>
                </Typography>
                <Typography variant="h5">
                  <Link color="inherit" href="/#services">Services</Link>
                </Typography>
                <Typography variant="h5">
                  <Link color="inherit" href="/#contact">Contact</Link>
                </Typography>
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpen}>
                <MenuIcon />
              </IconButton>
              <Menu  
                id="Menu"  
                anchorEl={anchorEl}  
                keepMounted  
                open={Boolean(anchorEl)}  
                onClose={handleClose}  
              >  
                <MenuItem className={classes.menuItem} onClick={handleClose}><Link color="inherit" href="#top">About</Link></MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleClose}><Link color="inherit" href="#bi">Business Intelligence</Link></MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleClose}><Link color="inherit" href="#services">Services</Link></MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleClose}><Link color="inherit" href="#contact">Contact</Link></MenuItem>
              </Menu>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
}
