import React from 'react';
import { makeStyles, Grid, Typography, Hidden, Container } from '@material-ui/core';
import Logo from '../assets/images/logo.png';
import Image from '../assets/images/footer-image.inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    overflow: 'hidden',
  },
  mainContainer: {
    padding: theme.spacing(12, 0),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    minHeight: 225,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  logoWrapper: {
    //marginTop: theme.spacing(5),
    position: 'relative',
    marginTop: 90,
    [theme.breakpoints.down('xs')]: {
      top: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '10%',
    },
  },
  logo: {
    width: '80%',
  },
  imageWrapper: {
    position: 'absolute',
    top: 90,
    [theme.breakpoints.down('xs')]: {
      top: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '10%',
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function SectionA() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer} id="contact">
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12} md={6}>
            <div className={classes.leftContainer}>
              <Typography variant="overline" gutterBottom>
                Let’s Talk.
              </Typography>
              <Typography variant="h4" gutterBottom>
                <a className={classes.link} href='mailto:info@candlestream.com'>info@candlestream.com</a> | <a className={classes.link} href='tel:12367000534'>+1 236-700-0534</a>
              </Typography>
              <Hidden smDown>
                <div className={classes.logoWrapper}>
                  <img className={classes.logo} src={Logo}></img>
                </div>
              </Hidden>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.rightContainer}>
              <div className={classes.imageWrapper}>
                <Image className={classes.image} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
